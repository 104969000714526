import React from 'react';
import { Link, NavLink, Outlet } from "react-router-dom";

import { ReactComponent as Logo } from './../images/logo.svg';

import { ReactComponent as NavHome } from './../images/nav-home.svg';
import { ReactComponent as NavSupport } from './../images/nav-support.svg';
import { ReactComponent as NavEducation } from './../images/nav-education.svg';
import { ReactComponent as NavPodcast } from './../images/nav-podcast.svg';
import { ReactComponent as NavCalendar } from './../images/nav-calendar.svg';

export default class BasePage extends React.Component {
  // We load our own jQuery so that there is no race condition when loading site page js files
  componentDidMount () {
      const script = document.createElement("script");

      script.src = "https://code.jquery.com/jquery-3.6.0.min.js";
      script.async = true;

      document.body.appendChild(script);
  }
  render() {
    return (
      <div className="base-page">
        <header id="header-wrap" className="wrap">
          <div className="wrap-content">
            <Link to="/" className="logo" title="Pennsylvania IPF Support Network" aria-label="Pennsylvania IPF Support Network">
              <Logo />
            </Link>
          </div>
        </header>
        <Outlet />
        <footer id="footer-wrap" className="wrap">
          <div className="wrap-content">
            <nav className="footer-nav">
              <NavLink to="/"><span className="nav-icon pad-more"><NavHome /></span><strong>Home</strong></NavLink>
              <NavLink to="/page/support/"><span className="nav-icon"><NavSupport /></span><strong>Support</strong></NavLink>
              <NavLink to="/page/education/"><span className="nav-icon"><NavEducation /></span><strong>Education</strong></NavLink>
              <NavLink to="/page/podcast/"><span className="nav-icon pad-less"><NavPodcast /></span><strong>Podcast</strong></NavLink>
              <NavLink to="/page/calendar/"><span className="nav-icon pad-more"><NavCalendar /></span><strong>Calendar</strong></NavLink>
            </nav>
          </div>
        </footer>
      </div>
    )
  }
}